// Color Palette
$prime: #ffd044;
$prime-dark: #9e8100;
$second: #0066ff;
$grey: #a0a0a0;
$red: #df2007;
$white: #ffffff;
$black: #000000;
$blackback: rgba(0, 0, 0, 0.8);
$green: #008000;

//old:$prime: #FFC107;

//padding & margin, sizing
$cube: 1rem;

//Fonts
$font1: "Malgun Gothic Semilight";
$font2: "Roboto", sans-serif;


//Definitions for pages

//Definition @media Variables
:root {
  --width-large-screen-size: 2200px;
  --width-tablet-size: 60em;
  --width-phone-size: 40em;
}

//Position X to close
.close {
    position: absolute;
    top: 20px;
    right: 50px;
    z-index: 10;
    font-size: 40px
}
  
.closePic{
    position: fixed;
    cursor: pointer;
}

//Definition Full Screen Page
.fullScreenPage {
    position:fixed;
    top: 60px;
    left: 2%;
    background-color: $white;
    width: 96%;
    height: 88%;
    box-shadow: 0 4px 15px $grey;
    margin: auto;
    margin-top: 20px;
    vertical-align: middle;
    overflow: auto;
    overflow-x: hidden;
    z-index: 5;
  }

@media (max-width: 60em) {
  .fullScreenPage {
      top: 50px;
      left: 2%;
      max-width: 95%;
      max-height: 82%;
  }
}

@media only screen and (max-width: 60em) and (orientation:landscape) {
  .fullScreenPage {
      position: fixed;
      top: 1%;
      max-height: 98%;
      left: 1%;
      max-width: 98%;
      z-index: 10;
  }
}

//Definition Full Screen Content
.fullScreenContent{
    margin: 40px;
    margin-top: 20px;
    position: absolute;
    font-size: 15px;
    color: #3c4858;
    overflow-x: hidden;
  } 
  
  @media (max-width: 60em) {
    .fullScreenContent {  
      margin: 20px;
    }
  }

//Link
.link {
    cursor:pointer;
    color:blue;
    text-decoration: none;
  }