/* You can add global styles to this file, and also import other style files */
@import './scss/var';

::selection{
    background-color: $black;
    color: $prime;
}

*{
    box-sizing: border-box;
}

h1{
    font-size: 22px;
    color: $black;
    font-family: -apple-system, BlinkMacSystemFont, Raleway, Helvetica Neue, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: lighter;
}

h2{
    font-size: 20px;
    color: $black;
    font-family: -apple-system, BlinkMacSystemFont, Raleway, Helvetica Neue, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: lighter;
}

h3{
    font-size: 16px;
    color: $black;
    font-family: -apple-system, BlinkMacSystemFont, Raleway, Helvetica Neue, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: lighter;
}

h4{
    font-size: 16px;
    color: $black;
    font-family: -apple-system, BlinkMacSystemFont, Raleway, Helvetica Neue, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: lighter;
}

body, html{
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    scrollbar-width: thin;
    scrollbar-color: $prime transparent;
    overscroll-behavior: none;
}

:host {
    font-family: -apple-system, BlinkMacSystemFont, Raleway, Helvetica Neue, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    color: #333;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

::-webkit-scrollbar {
    width: 20px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-track-piece{
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: $prime;
    border-radius: 20px;
    border: 7.5px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color:  #ffd0449d;
  }
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
