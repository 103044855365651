/**
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$rate-primary: mat-palette($mat-blue-gray 500);
$rate-accent: mat-palette($mat-amber, 300, A200, A400);

// The warn palette is optional (defaults to red).
$rate-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$rate-theme: mat-light-theme((
  color: (
    primary: $rate-primary,
    accent: $rate-accent,
    warn: $rate-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($rate-theme);

**/


@import '/node_modules/@angular/material/theming';

@include mat-core();

$my-red: (
  50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: #f44336,
  600: #e53935,
  700: #d32f2f,
  800: #c62828,
  900: #b71c1c,
  A100: #ff8a80,
  A200: #ff5252,
  A400: #ff1744,
  A700: #d50000,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$my-grey: (
  50: #fafafa,
  100: #f5f5f5,
  200: #eeeeee,
  300: #e0e0e0,
  400: #bdbdbd,
  500: #9e9e9e,
  600: #757575,
  700: #616161,
  800: #424242,
  900: #212121,
  A100: #ffffff,
  A200: #eeeeee,
  A400: #bdbdbd,
  A700: #616161,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  )
);

$my-yellow: (
  50: #ffd044,
  100: #ffd044,
  200: #ffd044,
  300: #ffd044,
  400: #ffd044,
  500: #9e8100,
  600: #9e8100,
  700: #9e8100,
  800: #9e8100,
  900: #9e8100,
  A100: #ffd044,
  A200: #ffd044,
  A400: #ffd044,
  A700: #ffd044,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);

$my-primary: mat-palette($my-yellow, 700);
$my-accent: mat-palette($my-yellow);

// The "warn" palette is optional and defaults to red if not specified.
$my-warn: mat-palette($my-red);

$my-theme-primary: mat-palette($my-primary);
$my-theme-accent: mat-palette($my-accent);
//the third, for the warn color, is the predefined material red palette
$my-theme-warn: mat-palette($my-red);

$my-theme: mat-light-theme($my-theme-primary, $my-theme-accent, $my-theme-warn);
@include angular-material-theme($my-theme);